.cont{
    width: 80%;
    height: 400px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    gap: 10px;
}
.imgbox{
    width: 55%;
    position: absolute;
    left: 0;
    top: 0;
    background: var(--fcoloraa);
    transition:.3s ease-in-out;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.zimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content{
    width: 55%;
    background: var(--fcolor);
    position: absolute;
    right: 0;
    height: 90%;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.content:hover .title{
    scale: 1.1;
}
.title{
    font-size: 23px;
    min-height: 40px;
    width: 80%;
    color: white;
    font-family: 'CustomFont2';
    transition:.3s ease-in-out;
}
.detail{
    color: white;
    height: 190px;
    width: 80%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.morebtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    position: absolute;
    bottom: 0;
}
.morebtn .button{
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content:center ;
    cursor: pointer;
    text-decoration: none;
    font-size: 25px;
    color: white;
    transition: all .3s;
    margin-right: 10px;
}
.morebtn .button:hover{
    transform: translateX(20%);
}

@media(max-width:700px){
    .cont{
        width: 100%;
    }
    .detail{
        height: 160px;
    }
}