.cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.box{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.searchbox{
    width: calc(100% - 20px);
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: rgba(0,0,0,.8);
    gap: 10px;
    justify-content: space-evenly;
    padding: 10px;
}
.searchlinks:hover{
    border:1px solid var(--fcolor);
}
.searchlinks{
    padding: 0 20px;
    height: 40px;
    border:1px solid transparent;
    color: var(--fcolor);
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lists{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    margin-bottom: 10px;
}