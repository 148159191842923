.cont{
    width: 320px;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.718);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    transition: all .3s;
}
.topbox{
    width: 90%;
    height: 280px;
    position: relative;
    overflow: hidden;
}
.img{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    position: absolute;
    z-index: 3;
    background: white;
    cursor: pointer;
    transition: .3s all linear;
}
.topbox:hover .img{
    z-index: 1;
    scale: 1.3;
}
.info{
    border-radius: 4px;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.links{
    display: flex;
    gap: 10px;
    height:45%;
    align-items:flex-start;
}
.icons{
    padding: 5px;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}
.icons:hover{
    background: white;
    color: black;
}
.type{
    background: white;
    width: 120px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'CustomFont2';
}
.name{
    width: 90%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.name a{
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
}
.name a:hover{
    color: var(--fcolor);
}
@media(max-width:900px){
    .cont{
        width:500px;
    }
}
@media(max-width:600px){
    .cont{
        width:400px;
    }
}
@media(max-width:500px){
    .cont{
        width:300px;
    }
}