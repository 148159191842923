.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
}
.box{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.gallerybox{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.vidbox{
    width: 45%;
    height: 500px;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}
.video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.playbox{
    width: 150px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
    background: rgb(232, 19, 19);
}
.imgsbox{
    width: 50%;
    height: 500px;
}
.myswiper{
    width: 100%;
    height: 100%;
}
.slide{
    width: 90%;
    height: 90%;
}
.zimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.lists{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    margin-top: 40px;
    position: relative;
}
.contlist{
    width: 100%;
    height: 90%;
    background: var(--fcoloraa);
    backdrop-filter: blur(10px);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.imgbox{
    width: 40%;
    height: 100%;
    background: var(--fcolor);
}
.textbox{
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.detailtitle{
    font-size: 30px;
    width: 90%;
    font-weight: bold;
    color: var(--fcolor);
}
.detaildes{
    color: white;
    width: 90%;
}
.orderbtn{
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: var(--fcolor);
    color: white;
    border: 2px solid transparent;
    margin-top: 20px;
    text-decoration: none;
}
.orderbtn:hover{
    background: white;
    color: var(--fcolor);
    border: 2px solid var(--fcolor);
}
@media(max-width:1000px){
    .lists{
        flex-direction: column;
        height:max-content;
        padding: 20px 0;
    }
    .imgbox{
        width: 90%;
        height:60%;
    }
    .textbox{
        width: 90%;
        height:max-content;
    }
    .contlist{
        width: 100%;
        height: 100%;
    }
    .gallerybox{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .vidbox{
        width: 100%;
        height: 400px;
    }
    .imgsbox{
        height: 400px;
        width: 100%;
    }
}