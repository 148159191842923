.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
}
.lists{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 80vh;
    margin-top: 40px;
    position: relative;
}
.contlist{
    width: 90%;
    height: 90%;
    background: var(--fcoloraa);
    backdrop-filter: blur(10px);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.imgbox{
    width: 40%;
    height: 100%;
    background: var(--fcolor);
}
.textbox{
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.detailtitle{
    font-size: 30px;
    width: 90%;
    font-weight: bold;
    color: var(--fcolor);
}
.detaildes{
    color: white;
    width: 90%;
}
@media(max-width:1000px){
    .lists{
        flex-direction: column;
        height:max-content;
        padding: 20px 0;
    }
    .imgbox{
        width: 90%;
        height:60%;
    }
    .textbox{
        width: 90%;
        height:max-content;
    }
    .contlist{
        width: 100%;
        height: 100%;
    }
}