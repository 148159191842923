.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}
.titlebox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.title{
    font-size: 30px;
}
.subtitle{
    text-align: center;
    opacity: .8;
}
.titleline{
    display: flex;
    align-items: center;
    justify-content: center;
}
.titleline span{
    width: 50px;
    height: 3px;
    background: rgb(220, 220, 220);
}
.titleline span:nth-child(2){
    background: var(--fcolor);
}
.lists{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 350px;
}
.myswiper{
    width: 100%;
    height: 100%;
}
@media(max-width:750px){
    .myswiper{
        width: 300px;
    }
}
/*
@media(max-width:1028px){
    .lists{
        grid-template-columns: repeat(2,auto);
    }
}
@media(max-width:680px){
    .lists{
        grid-template-columns: auto;
        justify-content: space-around;
        align-items: center;
    }
} */
