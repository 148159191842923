.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}
.box{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    align-items: center;
}
.formbox{
    width: 500px;
    min-height: 480px;
    border-top: 3px solid var(--fcolor);
    border-bottom: 3px solid var(--fcolor);
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
}
.inputcont{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.inputcont span{
    font-size: 17px;
}
.inputcont input{
    border: 1px solid rgba(128, 128, 128, 0.499);
    outline: none;
    width: calc(100% - 6px);
    padding: 3px;
    height: 30px;
    font-size: 16px;
    border-radius:5px;
}
.inputcont textarea{
    border: 1px solid rgba(128, 128, 128, 0.499);
    font-size: 16px;
    width: calc(100% - 10px);
    height: 150px;
    padding: 5px;
    border-radius:5px;
    outline: none;
    resize: none;
}
@media(max-width:1020px){
    .inputcont input{
        width: 300px;
    }
}
@media(max-width:900px){
    .inputcont textarea{
        width: 600px;
    }
}
@media(max-width:800px){
    .inputcont textarea{
        width: 400px;
    }
    .inputcont input{
        width: 250px;
    }
}
@media(max-width:600px){
    .formbox{
        justify-content: center;
        width: 95%;
    }
    .inputcont textarea{
        width: 300px;
    }
    .inputcont input{
        width: 300px;
    }
}
@media(max-width:380px){
    .formbox{
        justify-content: center;
    }
    .inputcont textarea{
    width: calc(100% - 10px);
    }
    .inputcont input{
    width: calc(100% - 6px);
    }
}
.inputcont input:focus{
    border: 1px solid var(--fcolor);
}
.inputcont textarea:focus{
    border: 1px solid var(--fcolor);
}
.btncont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.formerr{
    height: 50px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
}
.sendbtn{
    width: 200px;
    height: 40px;
    background: var(--fcolor);
    border-radius: 4px;
    border: 1px solid var(--fcolor);
    color: white;
}
.sendbtn:hover{
    background: white;
    color: var(--fcolor);
}
