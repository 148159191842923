.cont{
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.imgbox{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .9;
    background: #d0d0d0;
}
.content{
    position: absolute;
    color:white;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 2px;
}
