.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;
}
.box{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    align-items: center;
}
.lists{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    width: 100%;
}
.locationbox{
    width: 500px;
    height: 500px;
    border-top: 3px solid var(--fcolor);
    border-bottom: 3px solid var(--fcolor);
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.location{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.infobox{
    display: flex;
    width: calc(100% - 60px);
    flex-direction: column;
}
.infobox span{
    font-size: 14px;
}
.infobox span:first-child{
    font-size: 14px;
    font-weight: bold;
}
.icons{
    font-size: 30px;
    background: var(--fcolora);
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.location:hover .icons{
    background: var(--fcolor);
}
.map{
    width: 90%;
    height:70vh;
    overflow: hidden;
    background: #a7a7a7;
}
.formbox{
    width: 580px;
    height: 480px;
    border-top: 3px solid var(--fcolor);
    border-bottom: 3px solid var(--fcolor);
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.inputcont{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}
.inputcont span{
    font-size: 17px;
}
.inputcont input{
    border: 1px solid rgba(128, 128, 128, 0.499);
    outline: none;
    padding: 3px;
    width: calc(100% - 6px);
    height: 30px;
    font-size: 16px;
    border-radius:5px;
}
.inputcont textarea{
    border: 1px solid rgba(128, 128, 128, 0.499);
    font-size: 16px;
    width: calc(100% - 10px);
    height: 150px;
    padding: 5px;
    border-radius:5px;
    outline: none;
    resize: none;
}
@media(max-width:700px){
    .formbox{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .inputcont textarea{
        width:450px;
    }
}
@media(max-width:500px){
    .inputcont textarea{
        width:300px;
    }
}
@media(max-width:350px){
    .inputcont textarea{
        width:270px;
    }
}
.inputcont input:focus{
    border: 1px solid var(--fcolor);
}
.inputcont textarea:focus{
    border: 1px solid var(--fcolor);
}
.btncont{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.formerr{
    height: 50px;
    text-align: center;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sendbtn{
    width: 120px;
    height: 40px;
    background: var(--fcolor);
    border-radius: 4px;
    border: 1px solid var(--fcolor);
    color: white;
}
.sendbtn:hover{
    background: white;
    color: var(--fcolor);
}
@media(max-width:1300px){
    .locationbox{
        width: 400px;
    }
    .formbox{
        width: 500px;
    }
}
@media(max-width:1050px){
    .locationbox{
        width: 100%;
    }
    .formbox{
        width: 100%;
    }
}