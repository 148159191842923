.cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.box{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.lists{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}