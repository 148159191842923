  .swiper {
    width: 100%;
    height: 85vh;
  }
  
  .swiperslide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
  }
  
  .swiperslide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiperslide span {
    background:var(--fcoloraa);
    color: white;
    width: 70%;
    padding: 30px 20px;
    font-weight: bold;
    z-index: 5;
    position: absolute;
}