.cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
}
.box{
    width: 90%;
    height: 100%;
}
.videobox{
    width: 100%;
    height: 70vh;
    background: transparent;
    position: relative;
}
.bouncebox{
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bouncebox span{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--fcolor);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    animation: bounce2 1s ease-out infinite;
}
  @keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-40px);}
      60% {transform: translateY(-15px);}
  }
.logo{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
.infobox{
    width: 70%;
    min-height: 200px;
    background: rgba(0, 0, 0, 0.658);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    color: rgb(255, 255, 255);
    border: 1px solid var(--fcolor);
    padding: 10px;
}

@media(max-width:600px){
    .infobox{
        width: 90%;
        justify-content: flex-start;
    }
}
.moto{
    font-size: 150%;
    font-weight: bold;
  font-family:'CustomFont1';
}
.moto2{
    font-size:200%;
    font-weight: bold;
  font-family:'CustomFont1';
}
.moto3{
    font-weight: bold;
    color: var(--fcolor);
  font-family:'CustomFont1';
}

.video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media(max-width:700px){
    .videobox{
        height: 60vh;
    }
}